import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import GameCardInfo from "./components/GameCardInfo";
import styles from './GameCard.css';
import { getCardHref, getCardImageInfo } from "./getCardInfo";
import { getDescriptionDisabled } from "./getDescriptionDisabled";
import getIsEarlyAccess from "./getIsEarlyAccess";
import { ButtonWithIcon, ButtonWithIconTypes } from '../../atoms/Buttons/ButtonWithIcon/ButtonWithIcon';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { EarlyAccessIcon } from '../../atoms/Icons/Styleguide/EarlyAccessIcon';
import { NavLink } from '../../atoms/Link/Link';
import { ImageTypes, LazyImage } from '../../atoms/ProgressiveImage/LazyImage';
import { FavoritesAnalyticsClickPageTypes } from '../../features/Favorites/FavoritesAnalyticsAi';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import GameCardErrorButton from "../GameCardErrorButton/GameCardErrorButton";

export enum GameCardTypes {
    SMALL = 'small',
    MEDIUM = 'medium',
    MEDIUM_PLUS = 'mediumPlus',
    LARGE = 'large',
    SUPER_LARGE = 'superLarge',
    DETAILED = 'detailed',
    LONG = 'long',
    SQUARE = 'square',
}

export type GameCardProps = {
    gameCardType: GameCardTypes;
    onClick?: (game: IGame) => void;
    allGamesTile?: boolean;
    disableDescription?: boolean;
    disableTitle?: boolean;
    game?: IGame;
    previousGame?: string;
    dataElementDescription?: string;
    noProgress?: boolean;
    pseudoTile?: boolean;
    headerLink?: string;
    onSeeAllClick?: () => void;
    isCobrandedLP?: boolean;
    isFavorite?: boolean;
    favoritePageType?: FavoritesAnalyticsClickPageTypes;
    doubleSize?: boolean;
    variation?: string;
};

export const GameCardSizeMap = {
    [GameCardTypes.DETAILED]: '280x280',
    [GameCardTypes.SMALL]: '280x280',
    [GameCardTypes.MEDIUM]: '280x280',
    [GameCardTypes.MEDIUM_PLUS]: '280x280',
    [GameCardTypes.LARGE]: '280x280',
    [GameCardTypes.SUPER_LARGE]: '280x600',
    [GameCardTypes.SQUARE]: '280x600',
    [GameCardTypes.LONG]: '384x216',
};

export const GameCard = React.memo((props: GameCardProps) => {
    const {
        game,
        previousGame,
        allGamesTile,
        gameCardType,
        pseudoTile,
        onSeeAllClick,
        disableDescription,
        dataElementDescription,
        headerLink,
        noProgress,
        onClick,
        isFavorite,
        favoritePageType,
        doubleSize,
        variation,
    } = props;
    const [isDescriptionDisabled, setIsDescriptionDisabled] = useState(disableDescription);

    useEffect(() => {
        const descriptionDisabled = getDescriptionDisabled(gameCardType, pseudoTile, disableDescription);

        setIsDescriptionDisabled(descriptionDisabled);
    }, [disableDescription, gameCardType, pseudoTile]);

    if (!allGamesTile && !game) {
        return <GameCardErrorButton gameCardType={gameCardType} dataElementDescription={dataElementDescription} />
    }

    if (!game?.name) {
        return null;
    }

    const { cardImageSrc, cardImageSrcWebP, cardImageAlt } = getCardImageInfo(game, gameCardType, allGamesTile, doubleSize);
    const cardHref = getCardHref(game, previousGame, allGamesTile, pseudoTile, headerLink);
    const allGamesIllustration = UrlService.toCDNUrl('/illustrations/header/illustrations-all-games.png');
    const allGamesIllustrationWebp = UrlService.toCDNUrl('/illustrations/header/illustrations-all-games.webp');
    const isEarlyAccessOnly = getIsEarlyAccess(game);
    // favorites click handling, AI
    const handleFavoriteGameClick = () => {
        if (game?.isFavorite && favoritePageType) {
            Analytics.trackEvent(Analytics.favorites.clickFavoriteGameTile({ game, pageType: favoritePageType }));
        }
    };

    return (
      <div data-variations={variation}>
          <NavLink
            onDragStart={() => false}
            draggable={false}
            onClick={() => {
                handleFavoriteGameClick();
                game && onClick && onClick(game);
                pseudoTile && onSeeAllClick && onSeeAllClick();
            }}
            to={cardHref()}
            className={classNames(styles.gameCard, styles[gameCardType], {
                [styles.allGamesTile]: allGamesTile,
            })}
            data-element-description={dataElementDescription}
            suppressHydrationWarning
            aria-label={cardImageAlt}
          >
              <div className={styles.gameCardWrapper}>
                  <GameCardInfo {...props} />
                  <div
                    className={classNames(
                      styles.imageContainer,
                      isEarlyAccessOnly && !allGamesTile && !pseudoTile && styles.earlyAccessContainer
                    )}
                  >
                      <div className={styles.imageOverlay} />
                      {allGamesTile && (
                        <div className={classNames(styles.image, styles.allGamesTileBg)} suppressHydrationWarning />
                      )}
                      {pseudoTile && (
                        <picture>
                            <source
                              srcSet={`/pseudo_tile_${GameCardSizeMap[gameCardType]}.webp`}
                              type="image/webp"
                            />
                            <img
                              className={classNames(styles.image)}
                              src={`/pseudo_tile_${GameCardSizeMap[gameCardType]}.webp`}
                              alt="Explore this category"
                              suppressHydrationWarning
                              loading="lazy"
                            />
                        </picture>
                      )}
                      {noProgress && !allGamesTile && !pseudoTile && (
                        <picture>
                            <source srcSet={cardImageSrcWebP} type="image/webp" />
                            <img
                              className={classNames(styles.image)}
                              src={cardImageSrc}
                              alt={cardImageAlt}
                              suppressHydrationWarning
                            />
                        </picture>
                      )}
                      {!noProgress && !allGamesTile && !pseudoTile && (
                        <LazyImage
                          imageType={ImageTypes.SQUARE}
                          img={cardImageSrc}
                          webp={cardImageSrcWebP}
                          className={classNames(styles.image)}
                          alt={cardImageAlt}
                          loader={true}
                        />
                      )}
                      {allGamesTile && (
                        <>
                            <LazyImage
                              imageType={ImageTypes.SQUARE}
                              img={allGamesIllustration}
                              webp={allGamesIllustrationWebp}
                              className={styles.allGamesTileBgImage}
                              alt="All games"
                              aria-hidden="true"
                              loader={true}
                            />
                            <ButtonWithIcon
                              className={styles.allGamesButton}
                              buttonType={ButtonWithIconTypes.SECONDARY}
                              caption="SEE_ALL_GAMES"
                            />
                        </>
                      )}
                      {isEarlyAccessOnly && !allGamesTile && !pseudoTile && (
                        <div className={styles.earlyAccessBadge}>
                            <I18nText className={styles.earlyAccessText} keyName="EARLY_ACCESS" />
                            <EarlyAccessIcon className={styles.earlyAccessIcon} />
                        </div>
                      )}
                      {isFavorite && !allGamesTile && !pseudoTile && <div className={styles.favoriteBadge}></div>}
                      {gameCardType !== GameCardTypes.DETAILED && !isDescriptionDisabled && (
                        <div className={styles.hiddenDescription}>
                            <div
                              className={classNames(
                                styles.hiddenDescriptionWrapper,
                                isEarlyAccessOnly && styles.additionalPadding
                              )}
                              role="tooltip"
                            >
                                <p className={styles.hiddenDescriptionGameName}>{game?.name}</p>
                                <p className={styles.hiddenDescriptionContent}>{game?.details}</p>
                            </div>
                        </div>
                      )}
                  </div>
              </div>
          </NavLink>
      </div>
    );
});