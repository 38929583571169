import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GemsAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    gemsPurchaseSuccess(internalPrice: any, purchasableItemId: string, gameId?: string): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('virtualItemPurchaseSuccess');
        event.setEventGaCategory('virtualItemPurchaseSuccess');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(purchasableItemId);
        if (typeof internalPrice === 'number' || internalPrice) {
            event.setCustom('event_value', internalPrice);
        }
        event.setGaNonInteraction(false);
        return event.build();
    }
}
