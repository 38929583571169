import { MiscUtils } from '../../../../utils/MiscUtils';
import { environment } from '../../../config/environment';
import { IGame } from '../../../models/Game/Game';
import { UrlService } from '../../UrlService';
import { Analytics } from '../Analytics';
import { AnalyticsEvent, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GeneralAnalyticsGa {
    displayAd(refresh: boolean, isViewable: boolean, game: IGame, size: number[]): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Display_Ads');
        const categoryValue = refresh ? 'refresh' : 'notrefresh';

        event.setCustom('event_category', categoryValue);
        let labelValue = '';

        if (game) {
            labelValue = Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug);
        } else {
            labelValue = Analytics.generateLabel(environment.ARENA_DOMAIN, UrlService.getPageNameForAnalytics(), '');
        }

        event.setCustom('event_label', labelValue);
        event.setGaNonInteraction(!refresh);
        return event.build();
    }

    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    private newEventBuilderWithData(category: string, game: IGame, home?: boolean) {
        const event = this.newEventBuilder();
        if (game) {
            event.setEventGaLabel(game.slug);
        } else if (category) {
            event.setEventGaLabel(category);
        } else if (home) {
            event.setEventGaLabel('arena home');
        }
        return event;
    }
}
