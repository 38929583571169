import { NestEnvironmentType } from '@arkadium/modules';

export enum EnvironmentName {
  LOCAL,
  DEV,
  DEV2,
  DEV3,
  STAGING,
  BRANCH,
  GAMESDEV,
  GAMESSTAGING,
  CANARY,
  PRODUCTION,
}

export interface Environment {
  // environment name
  Name: EnvironmentName;
  // Blobs
  GAME_API_URL: string;
  GAMES_CONTAINER_URL: string;
  SORTING_URL: string;
  GAME_ARTS: string;
  GAME_METAS: string;
  WHYNOTTRYTHESE_URL: string;
  CONNECTED_APPS_URL: string;
  HOMEPAGE_GAMES_URL: string;
  HOMEPAGE_GAMES_URL_REDESIGN: string;
  GAMES_SORTED_BY_GAME_STARTS: string;
  IFRAME_GAMES_LIST_PATH: string;
  GRM_GAMES_LIST_PATH: string;
  AB_TESTS: string;
  ARK_CONFIG: string;
  // Feed
  FEED_ID: string;
  FEED_NAME: string;
  // Arena
  ARENA_DOMAIN: string;
  SITE_BASE_URL: string;
  // AD
  AD_REFRESH_TIME_MS: number;
  // CDN
  USE_CDN: boolean;
  CDN_BASE_URL_OVERWRITES: string;
  // APP INSIGHT
  APP_INSIGHT_INSTRUMENTATION_KEY: string;
  APP_INSIGHT_INSTRUMENTATION_KEY_FRONT: string;
  VALIDATE_ANALYTICS_TRACKS: boolean;
  ADO_BUILD_ID: string;
  // local and session storage prefix
  STORAGE_PREFIX: string;
  // determine wether to show server errors in browser with details or simple message
  ERROR_DETAILS: boolean;
  // cache time for memored service
  CACHE_TIME_MS: number;

  PAGE_SEO_URL: string;

  NON_CAT_PAGE_SEO: string;

  CO_BRANDED_PAGE_SEO: string;

  AFFILIATE_DATA: string;

  STRAPI_URL: string;
  STRAPI_API_KEY: string;
  PATH: string;

  REDIRECTS_SETTINGS_PATH: string;
  PUSH_NOTIFICATIONS_URL: string;

  // api urls
  USER_API_BASE_URL: string;
  SCORE_API_BASE_URL: string;
  BLOB_IMAGES_BASE_URL: string;

  EAGLE_API_BASE_URL: string;
  EAGLE_API_PAYMENT: string;
  RECURLY_BILLING_PAGE: string;
  EAGLE_USER_GAME_DATA_API: string;

  EAGLE_API_COLLECTIONS_URL: string;

  DISPLAY_ADS: string;
  VIDEO_ADS: string;
  ADS_QUERY_PARAM: string;
  ADS_TXT: string;

  RECURLY_PUBLIC_KEY: string;
  RECURLY_PLAN_PREFIX: string;
  RECURLY_GEMS_PREFIX: string;
  RECURLY_GIFT_CARD_PRODUCT_CODE: string;
  LEANPLUM_APP_ID: string;
  LEANPLUM_API_KEY: string;

  OPEN_WEB_KEY: string;

  SKU_GEMS_SKIP_AD: string;
  SKU_GEMS_SKIP_AD_BUNDLE: string;
  SKU_GEMS_NAME: string;

  HOTJAR_SITE_ID: number;

  TAGMANAGER_SITE_ID: string;

  RECAPTCHAAPPKEY: string;
  CHALLENGE_CAPTCHA_KEY: string,
  CDN_CAPTCHA_IFRAME: string,

  NEST_ENVIRONMENT: NestEnvironmentType;
}
