class QuantcastServiceFactory {
    readonly gdprCookieConsent = 'gdprCookieConsent';
    readonly targetingConsent = 'targetingConsent';

    public init () {
        // @ts-ignore
        if (window.__tcfapi) {
            // @ts-ignore
            window.__tcfapi('addEventListener', 2, this.onChanges);
        } else {
            this.setConsent(true);
            this.setGdprCookieConsent(false);
        }
    }

    onChanges = () => {
        try {
            // @ts-ignore
            __tcfapi('getTCData', 2, (tcData) => {
                try {
                    this.setConsent(Boolean(tcData.purpose.consents[1]));
                    console.log('targetingConsent =' + Boolean(tcData.purpose.consents[1]));
                } catch (ex) {
                    this.setConsent(false);
                    console.log('Exception: targetingConsent = false');
                }

                try {
                    if (tcData.gdprApplies) {
                        const consent = Boolean(tcData.purpose.consents[1])
                            && Boolean(tcData.vendor.consents[11]);

                        this.setGdprCookieConsent(false); // disable for all EU users
                        console.log('gdprConsent =' + consent);
                    } else {
                        this.setGdprCookieConsent(true);
                    }
                } catch (ex) {
                    this.setGdprCookieConsent(false);
                    console.log('Exception: gdprConsent  =' + this.getGdprCookieConsent());
                }
            });
        } catch (e) {
            this.setConsent(true);
            this.setGdprCookieConsent(true);
        }
    }
    blockedByGdprConsent = () => {
        if (this.getGdprCookieConsent() == null) {
            return true;
        }

        return this.getGdprCookieConsent() === 'false';
    }
    private setConsent(consent: boolean) {
        try {
            localStorage.setItem(this.targetingConsent, String(consent));
        } catch (e) {
            console.error('Failed to save to localStorage:', e);
        }
    }

    private setGdprCookieConsent(consent: boolean) {
        try {
            localStorage.setItem(this.gdprCookieConsent, String(consent));
        } catch (e) {
            console.error('Failed to save to localStorage:', e);
        }
    }

    private getGdprCookieConsent = () => {
        const consent = localStorage.getItem(this.gdprCookieConsent);

        console.log('getGdprCookieConsent=' + consent);
        return consent;
    }

}

export const QuantcastService = new QuantcastServiceFactory();
