import { MiscUtils } from '../../../../utils/MiscUtils';
import { IGame } from '../../../models/Game/Game';
import {
    AnalyticsEvent,
    AnalyticsEventAction,
    AnalyticsEventBuilder,
    MobileBookmarkPwaData,
} from '../AnalyticsEventBuilder';
import { WebVitals } from '../../../models/WebVitals';

export class GeneralAnalyticsAi {
    async promoBoxSlot(game: IGame, index: number): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('promoboxSlot');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('promoboxSlotClick', `${game.slug} | ${index} | true`.toLowerCase());
        return event.build();
    }

    async topNavClick(category: string, index: number): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('TopNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', `${category}|${index}`.toLowerCase());
        return event.build();
    }

    async recommendedGameClick(gameTag: string, game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('recommendedGames');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('recommendedGamesClick', gameTag.toLowerCase());
        return event.build();
    }

    async displayAd(refresh: boolean, game: IGame, size: number[], displayAdLocation: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('displayAd');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(!refresh);
        if (game) {
            await event.setGame(game);
        }
        event.setCustom('displayAdSize', `${size[0]}x${size[1]}`);
        event.setCustom('displayAdType', refresh ? 'refresh' : 'notrefresh');
        event.setCustom('displayAdLocation', displayAdLocation);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async searchBoxSelect(searchText: string, game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchBox');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (game) {
            await event.setGame(game);
        }
        event.setCustom('searchQuery', searchText.toLowerCase());
        return event.build();
    }

    async bookmarkImpression(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Bookmark');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async bookmarkClick(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Bookmark');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async breadcrumbClick(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('breadCrumb');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkImpression(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmark');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkClick(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmark');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkDrag(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmark');
        event.setEventAction(AnalyticsEventAction.DRAG);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkVisit(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmark');
        event.setEventAction(AnalyticsEventAction.VISITS);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkPWAInstall(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.INSTALL);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkPWACancel(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.CANCEL);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkPWAClick(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkPWAVisit(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.VISITS);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async desktopBookmarkPWAImpression(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('DesktopBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async mobileBookmarkPWAClick(data: MobileBookmarkPwaData): Promise<AnalyticsEvent> {
        const { game, platform } = data;
        const event = await this.newEventBuilder();
        event.setName('mobileBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        event.setCustom('bookmarkType', platform);
        return event.build();
    }

    async mobileBookmarkPWAImpression(data: MobileBookmarkPwaData): Promise<AnalyticsEvent> {
        const { game, platform } = data;
        const event = await this.newEventBuilder();
        event.setName('mobileBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        event.setCustom('bookmarkType', platform);
        return event.build();
    }

    async mobileBookmarkPWAVisit(data: MobileBookmarkPwaData): Promise<AnalyticsEvent> {
        const { game, platform } = data || {};
        const event = await this.newEventBuilder();
        event.setName('mobileBookmarkPWA');
        event.setEventAction(AnalyticsEventAction.VISIT);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        event.setCustom('bookmarkType', platform);
        return event.build();
    }

    async AndroidBookmarkVisit(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('AndroidBookmark');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async androidInstallAdd(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('AndroidBookmark');
        event.setEventAction(AnalyticsEventAction.INSTALL);
        event.setNonInteraction(false);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async androidBookmarkVisits(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('AndroidBookmark');
        event.setEventAction(AnalyticsEventAction.VISITS);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async IOSBookmarkVisit(game?: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('IOSBookmark');
        event.setEventAction(AnalyticsEventAction.VISITS);
        event.setNonInteraction(true);
        if (typeof game !== 'undefined') {
            await event.setGame(game);
        }
        return event.build();
    }

    async pushNotifications(
        action: string,
        label: string,
        nonInteration: boolean,
        category: string,
        game: IGame
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Opt_in');
        event.setEventAction(action);
        if (category !== null) {
            event.setCustom('category', category);
        }
        if (game !== null) {
            await event.setGame(game);
        }
        event.setNonInteraction(nonInteration);
        return event.build();
    }

    async promoboxSlotClick(
        cardSize: string,
        location: string,
        game: IGame,
        position: number
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('promoboxSlot');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (game !== null) {
            await event.setGame(game);
        }
        event.setCustom('promobox Type', `${cardSize}|${location}|${position}`);
        return event.build();
    }

    async footerImpression(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('FooterCatNav');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        return event.build();
    }

    async footerNavClick(category: string, index: number): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('FooterCatNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', `${category}|${index}`.toLowerCase());
        return event.build();
    }

    async searchBoxImpression(searchType: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchBox');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        event.setCustom('topnavImpression', searchType.toLowerCase());
        return event.build();
    }

    async searchBoxQuery(searchType: string, query: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchBox');
        event.setEventAction(AnalyticsEventAction.QUERY);
        event.setNonInteraction(false);
        event.setCustom('topNavClick', searchType.toLowerCase());
        event.setCustom('searchQuery', query.toLowerCase());
        return event.build();
    }

    async searchBoxClick(searchType: string, slugOrCategory: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchBox');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topNavClick', searchType.toLowerCase());
        event.setCustom('game', slugOrCategory.toLowerCase());
        return event.build();
    }

    async topNavAdvantage(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('TopNavAdvantage');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async homepageCarousel(slideLabel: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(slideLabel);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async carouselButton(slot: string, buttonLabel: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('CarouselButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('slot', slot);
        event.setCustom('buttonLabel', buttonLabel);
        return event.build();
    }

    async webVitals(metrics: WebVitals): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();

        event.setName('WebVitals');
        Object.keys(metrics).forEach(key => {
            event.setCustom(key, metrics[key]);
        });

        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
