import React from 'react';

import classnames from 'classnames';

import styles from './DisplayAd.css';
import { MiscUtils } from '../../../utils/MiscUtils';

interface DisplayAdProps {
    dimensions: number[][];
    id: string;
    refreshRate?: number;
    className?: string;
    bidtimeout?: number;
    viewableOnly?: boolean;
    keyValues?: [string[]];
    style?: React.CSSProperties;
}

class DisplayAdBase extends React.Component<DisplayAdProps> {
    static displayName = 'DisplayAd';

    get adOptions() {
        const { id, dimensions, bidtimeout, refreshRate, viewableOnly } = this.props;

        const resProps: any = {
            product: 'arena',
            dimensions: JSON.stringify(dimensions),
            'data-id': id,
            viewableOnly,
        };

        if (bidtimeout) {
            resProps.biddertimeout = String(bidtimeout);
        }

        if (refreshRate) {
            resProps.refreshrate = refreshRate;
        }

        return resProps;
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    render() {
        const { className, style } = this.props;

        if (MiscUtils.isServer) {
            return null;
        }

        return (
            <Container className={className} style={style}>
                <display-ad-component key={this.adOptions['data-id']} {...this.adOptions} />
            </Container>
        );
    }
}

const Container = ({ className, ...props }) => <div className={classnames(styles.container, className)} {...props} />;

export const DisplayAd = DisplayAdBase;
